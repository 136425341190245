/*
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2020-12-27 20:10:38
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 13:39:04
 * @FilePath: /refactor-with-vue3/src/main.js
 */
import { createApp} from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import axios from "@/plugins/axios";

// import SvgIcon from '@/components/SvgIcon'// svg组件
import 'lib-flexible/flexible'

import { Icon } from 'vant';
import components from '@/components' //

import Vant from 'vant'
import {Toast} from 'vant'
import { Lazyload } from 'vant';

import 'vant/lib/index.css' // 全局引入样式

import eventBus from './utils/eventBus'
import {loginToPlatform} from "./utils/util";
// import JsBridge  from "./plugins/JsBridge"

//页面跳转窗口定位顶部
router.afterEach((to, from, next) => {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    window.scrollTo(0, 0);
})
const app = createApp(App);
// app.config.silent = false;
// app.config.globalProperties.$bridge = JsBridge;
app.use(Lazyload);

app.use(Vant).use(store).use(router).use(axios).mount('#app');
app.use(Icon)
app.use(Toast)
app.use(components)
app.use(eventBus)
app.use(loginToPlatform)
