

//注册和登录
import axios from "../plugins/axios";

export function fastLogin (params) {

  return axios.post('/wenwu-user/user/login', params);
}

export function postUserSms (params) {
  return axios.post('/utils/sms', params);
}


export function registerAndLogin (params) {
  return axios.post('/user/login', params);
}

export const shareInfo = {
  title: '星城玩家',
  desc: '我的长沙全新栏目星城玩家正式上线！点我进入就能免费领限量版数字纪念徽章，还有五一游长沙系列数字文创等你来领~',
  logo: window.location.origin + '/icon_share_logo.jpg',
  link: window.location.origin + '/share',

}

// export const shareInfo2 = {
//   title: '星城玩家',
//   // desc: '我的长沙全新栏目星城玩家正式上线！点我进入就能免费领限量版数字纪念徽章，还有五一游长沙系列数字文创等你来领~',
//   desc: '参与湖南博物院\“文物中的美好生活\”主题活动,了解更多文物小知识,还能免费领取数字纪念徽章~',
//   logo: window.location.origin + '/icon_share_518.jpg',
//   link: window.location.origin + '/bwgShare',

// }
export const shareInfo2 = {
  title: '星城玩家',
  desc: '快来参与\“国际生物多样性日\”主题问答活动，了解相关知识，赢取数字微章，开启\“保护生物多样性\”的每日生活',
  logo: window.location.origin + '/icon_share_522.jpg',
  link: window.location.origin + '/bwgShare',

}
