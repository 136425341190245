<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2020-04-29 22:08:12
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 14:32:14
 * @FilePath: /refactor-with-vue3/src/App.vue
 -->
<template>
<!--  <keep-alive>-->
<!--  <transition name="fade-transform" mode="out-in">-->

<!--    <router-view></router-view>-->
<!--  </transition>-->
<!--  </keep-alive>-->
<!--  :include="['home']"-->
<!--  -->


  <router-view v-slot="{ Component }">
    <keep-alive  include="newHome">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>

import {computed, reactive, ref, toRefs, watch} from "vue";
import store from "./store";

export default {
  setup() {

    const includeList = computed(()=>{

      return  store.state.user.routers;
    })


    return {
      includeList
    }
  }


}
</script>
<style lang="scss">
@import "./styles/base.scss";
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}
</style>
