import {getSessionStore, getStore, removeStore, setSessionStore, setStore} from "../../utils/util";


const userInfokey = 'USER_INFO_KEY';
const routeskey = 'ROUTES_KEY'
const indexRoutesKey = 'INDEX_ROUTES_KEY'

const chainBackOrderKey = 'CHAIN_BACK_ORDER_KEY'


// const showBindPhone = 'BIND_PHONE_KEY'

const state = {
    user:getStore(userInfokey),
    routers:getSessionStore(routeskey) || [],
    indexRouters:getSessionStore(indexRoutesKey) || [],
    chainBackOrderId:getSessionStore(chainBackOrderKey),
    // showBindPhone:getSessionStore(showBindPhone),
}

// getters
const getters = {


}

// actions
const actions = {



}

// mutations
const mutations = {

    setUserInfo(state,user){
        state.user = user;
        if(user === null){
            removeStore(userInfokey);
        }else{
            setStore(userInfokey,user)
        }

    },

    changeUserInfoWithKey(state,changeInfo){

        for (let k in changeInfo){
            state.user[k] = changeInfo[k]
        }
        const {user } = state.user
        setStore(userInfokey,state.user)

    },


    changeUserAgreementState(state,changeInfo){

        for (let k in changeInfo){
            state.user.agreement[k] = changeInfo[k]
        }
        setStore(userInfokey,state.user)
    },


    addRouterState(state,route){

        if(state.routers.indexOf(route) === -1){
            state.routers.push(route)

        }
        setSessionStore(routeskey,state.routers)

    },

    addIndexRouterState(state,route){

        if(state.indexRouters.indexOf(route) === -1){
            state.indexRouters.push(route)

        }
        setSessionStore(indexRoutesKey,state.indexRouters)

    },

    removeRouteState(state,route){

        if(state.routers.indexOf(route) != -1){
            state.routers.splice(state.routers.indexOf(route),1)
        }
        setSessionStore(routeskey,state.routers)

    },

    removeIndexRouteState(state,route){

        if(state.indexRouters.indexOf(route) != -1){
            state.indexRouters.splice(state.indexRouters.indexOf(route),1)
        }
        setSessionStore(indexRoutesKey,state.indexRouters)

    },

    setChainBackOrderId(state,orderId){

        state.chainBackOrderId = orderId
        setSessionStore(chainBackOrderKey,state.chainBackOrderId)
    },

    // setShowBindPhone(state,show){
    //     state.showBindPhone = show
    //     setSessionStore(showBindPhone,state.showBindPhone)
    // }

}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}