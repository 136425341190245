<template>

  <div class="main">
    <div class="topInfo">
      <img :src="require('../../assets/mine/icon_tranfer.png')" alt="">
    </div>
    <div v-if="data.show" class="confirm">

      <img :src="require('../../assets/mine/icon_mine_warn.png')" alt="">
      <p class="warn">请确认转赠到以下信息</p>

      <div class="zz-info">
        <div v-if="!data.isPhone">
          <p>区块链地址：</p>
          <p>{{data.address}}</p>
        </div>
          <p v-else>手机号码：{{data.address}}</p>

      </div>

      <div class="button-info">
        <div class="query-btn" @click="submitTran">确认并提交</div>
        <div class="cancel-btn" @click="cancelAction">取消</div>
      </div>

    </div>
    <div v-else class="result">
        <van-image  class="state-icon" :src="require('../../assets/mine/icon_order_finish.png')"/>
        <p   class="state-desc-success">转赠成功</p>
         <div class="back-mine" @click="backMine">返回我的</div>
    </div>
  </div>

</template>

<script>
import {formValidate} from "../../utils/util";

export default {
  props:{
    address:String,
    isResult:Boolean
  },
  name: "confirmTranfer",
  emits:['backMine','cancel','submitTranfer'],
  setup(props,ctx){

    const data = {
      show:!props.isResult,
      address:props.address,
      isPhone:false
    }

    if(formValidate(data.address,'phone')){

      data.isPhone = true
    }

    const backMine = () => {
      ctx.emit('backMine')
    }

    const cancelAction = () => {
      ctx.emit('cancel')
    }

    const submitTran = () => {
      ctx.emit('submitTranfer')
    }

    return {
      data,
      backMine,
      cancelAction,
      submitTran
    }

  }
}
</script>

<style scoped lang="scss">
@import "src/styles/mixin";
.main {

  width: calc(100vw - 76px);
  background-color: #fff;
  border-radius: 20px;
  padding: 18px 15px 13px;

  .topInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 84px;
      height: auto;
      display: block;
    }

  }

  .confirm {
    width: 100%;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 36px;
      display: block;

    }
    .warn {
      color: #FF872D;
      font-size: 14px;
      text-align: center;
      margin-top: 6px;

    }

    .zz-info {
      border-radius: 8px;
      border: 1px dashed #E5E5E5;
      margin-top: 6px;
      word-break: break-all;
      width: 100%;
      padding: 8px 19px;
      text-align: center;
      font-size: 14px;
      color: #000;
      font-weight: bold;

    }

    .button-info {
      width: 100%;
      margin-top: 21px;
      .query-btn {
        background-image: linear-gradient(to bottom,$color-left ,$color-right);
        min-width: 100%;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        color: white;
      }

      .cancel-btn {
        margin-top: 12px;
        border:  1px solid $color-right;
        min-width: 100%;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        color: $color-right;
      }

    }

  }

  .result {
    padding-top: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .state-icon {
      width: 48px;
      height: 48px;
    }
    .state-desc-success {
      font-size: 16px;
      font-weight: bold;
      margin-top: 8px;
      color:#000000;
    }

    .back-mine {
      margin-top: 85px;
      border:  1px solid $color-right;
      min-width: 100%;
      height: 40px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
      color: $color-right;
    }

  }


}


</style>
