<template>

  <div class="main">

    <div class="topInfo">
      <img :src="require('../../assets/mine/icon_tranfer.png')" alt="">
    </div>

    <p class="desc">请输入要转赠的手机号或者区块链地址</p>

    <van-field
      class="input-class"
      label-width="0"
      v-model="data.text"
      placeholder="请输入"
      input-align="left"
      type="textarea"
      rows="1"
      autosize
      maxlength="100"
    />

    <p class="tips">{{data.tips}}</p>

    <div @click="submitData" class="bottomButton">
      提交
    </div>

  </div>

</template>

<script>
import {reactive} from "vue";
import {showFailToast} from "../../utils/util";

export default {
  name: "tranferDialog",
  emits:['submit'],
  props:{
    tips:{
      String,
      default:''
    }
  },
  setup(props,ctx){

    const data = reactive({
      text:'',
      tips:props.tips

    })
    const submitData = () => {

      if(data.text.trim().length <= 0) {
        showFailToast('请输入手机号或区块链地址');
        return;
      }
      ctx.emit('submit',data.text);
    }

    return {
      submitData,
      data
    };
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/mixin";

.main {

  width: 100%;
  background-color: #fff;
  border-radius: 20px 20px 0 0 ;
  padding: 25px 18px 16px;

  .topInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 84px;
      height: auto;
      display: block;
    }

  }

  .desc {
    font-size: 14px;
    color: #4B4B4B;
    margin-top: 29px;
  }

  .input-class {
    margin-top: 12px;
    padding: 0 14px;
    background-color: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    //--van-cell-vertical-padding:15px;
    //--van-cell-horizontal-padding:14px;
    --van-text-color:#000;

    :deep(.van-field__control--min-height ) {
      min-height: 40px;
      max-height: 60px;
    }

    :deep(.van-field__control){
      padding: 10px 0;
      vertical-align: center;
      font-size: 14px;
      color: #000;
      font-weight: bold;
    }

  }

  .tips {
    margin-top: 14px;
    font-size: 12px;
    color: #8A8A8A;
  }

  .bottomButton {
    margin-top: 50vw;
    background-image: linear-gradient(to bottom,$color-left ,$color-right);
    width: calc(100vw - 54px);
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    margin-left: 9px;
    color: white;

  }



}


</style>
