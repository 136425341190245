<template>
      <van-nav-bar title="数字徽章" left-arrow :safe-area-inset-top="true" fixed  :border="false" @click-left="goBack" right-text="绿色通道"
      class="nav" @click-right="go">
 
  </van-nav-bar>

</template>
<script>
import { useRouter } from "vue-router";
export default{
    name:"TopBar",
    setup(){
     const $router = useRouter();
     const goBack=(()=>{
        $router.back(-1)
      })     
      const go=(()=>{
        console.log("点击了")
        console.log($router)
        $router.push("/nftDetail/share")
      }) 
      return {
        goBack,
        go
      } 
    }
}
</script>
<style scoped lang="scss" src="./index.scss"></style>

