<template>
  <div>
    <section class="main-answer">
      <!-- 确认是否参与答题弹窗 -->
      <div class="answerBox" v-if="logoFlag">
        <div class="img1">
          <img src="../../assets/popover-home/编组9.png" alt />
          <div class="img2">
            <img @load="answerOption" src="../../assets/popover-home/编组4.png" alt />
          </div>
          <div v-show="answerOptionFlag">
            <div class="text">
              <span>参与主题问答 赢取免费好礼</span>
            </div>
            <div class="button">
              <button class="button_1" @click="goToGuideline">
                <!-- <img src="../../assets/popover-home/编组6.png" alt=""> -->
                <span class="text_1">知识攻略</span>
              </button>

              <button class="button_2" @click="onClick_2">
                <!-- <img src="../../assets/popover-home/编组6.png" alt=""> -->
                <span class="text_2" @click="gotoAnswer">立即答题</span>
              </button>
            </div>
            <div class="cancel" @click="closeQuesion">
              <img src="../../assets/popover-home/cancelIcon.png" alt />
            </div>
          </div>
        </div>
      </div>

      <!-- 去知识攻略弹窗 -->
      <div class="guideline" v-if="guidelineFlag">
        <div style="align-self: center;">
          <!-- 路由回退角标 -->
          <div class="callbackImg" @click="goBack">
            <img src="../../assets/popover-home/编组2备份.png" alt />
          </div>

          <div class="guidelineBox">
            <!-- 标题 -->
            <div class="guidelineText">
              <img
                style="width:100%"
                referrerpolicy="no-referrer"
                src="../../assets/popover-home/guide_title.png"
              />
            </div>

            <!-- 攻略长图 -->
            <div class="guidelineImg">
              <img v-if="isWw"  :src="wwGlImg" alt="">
              <img v-else  src="../../assets/popover-home/知识攻略@2x.jpg" alt />
            </div>
            <div class="guideline-right-img">
              <img src="../../assets/popover-home/矩形.png" alt />
            </div>
          </div>
        </div>
      </div>

      <!-- 立即答题弹窗 -->
      <section class="answer" v-show="answerFlag">
        <div class="answer_div" style>
          <!--        <img-->
          <!--          style="width: 100%; height: 100%;"-->
          <!--          src="../../assets/popover-home/answer/answer_3.png"-->
          <!--          alt-->
          <!--          @load="answerShow"-->
          <!--        />-->
          <div v-show="answerDiv" class="answer_img2">
            <div class="answer_img1" @click="back">
              <img src="../../assets/popover-home/answer/answer_1.png" alt />
            </div>
            <!-- 问题 -->

            <div class="answer_text"  @load="goodsListItemImg">
              <!-- <img src="" alt=""> -->
              <div class="answer-content">
                <div class="answer_text_top">
                  <div
                    :class="this.currentQuestion.q.length < 19 ? 'test' : 'test1'"
                  >{{ this.currentQuestion.q}}</div>
                  <div
                    :class="this.currentQuestion.q.length < 13 ? 'answer_img' : 'answer_img'"
                    align="center"
                  >
                    <!-- <img :src="this.currentQuestion.img" alt /> -->
                  </div>
                </div>
                <!-- 选项 -->
                <div
                  v-if="isError == false"
                  style="display: flex;flex-shrink: 0;flex-direction: column;  "
                >
                  <div
                    style="font-size: 13px;"
                    :class="active === index? 'answer_item answer_item_active':'answer_item' "
                    v-for="(item,index) in this.currentQuestion.answer"
                    :key="index"
                    @click="confirm(index,item.option)"
                  >
                    <div>{{item.option}}. {{item.optionQ}}</div>
                  </div>
                </div>

                <!-- 错误提示 -->
                <div v-else style="height:33%">
                  <div class="error_item">
                    <div
                      v-for="(item,index) in currentQuestion.answer"
                      :key="index"
                      :class="currentQuestion.answer[0].optionQ.length>9?'special_error':'error_option'"
                      style
                    >{{item.option}}. {{item.optionQ}}</div>
                  </div>

                  <!-- 错误和正确选项 -->
                  <div
                    class="question_option"
                    style="display: flex;justify-content: space-between;"
                  >
                    <div>
                      正确答案:
                      <span style="color:#BA0000">{{currentQuestion.rightAnser}}</span>
                    </div>
                    <div>
                      已选答案:
                      <span style="color:#BA0000">{{this.option}}</span>
                    </div>
                  </div>
                  <!-- 解析 -->
                  <div class="analysis">解析:{{currentQuestion.analysis}}</div>
                </div>
              </div>
              <div v-if="isError==false" class="submit" @click="submit">
                <span class="answer_button_text">确定</span>
              </div>
              <div v-else class="again" @click="again">
                <span class="answer_button_text">再答一次</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style="padding: 1rem" v-show="rightAnswers">
        <div class="successWindow">
          <img style="width: 100%;" src="../../assets/popover-home/success/icon_1.png" alt />
          <div class="small_logo">
            <div style="width: 70%; margin: auto;">
              <img
                @load="successShow"
                style="width:100%"
                src="../../assets//popover-home/success/icon_2.png"
                alt
              />
            </div>

            <div v-show="successShowFlag">
              <div class="powerful">恭喜您！回答正确</div>
              <div class="active_award_text">
                活动奖励：“从协议到协力：复元生物多样性”数字纪念徽章已发放至您的账户
              </div>
              <div class="click_views" @click="views">
                <span class="answer_button_text">点击查看</span>
              </div>
            </div>
          </div>
          <div class="closeImg" @click="quit()">
            <img style="width: 100%;" src="../../assets/popover-home/success/icon_3.png" alt />
          </div>
        </div>
      </div>
    </section>

















  </div>
</template>

<script >
import { reactive } from "vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import {getStore, showFailToast,clearToast, showSuccessToast,showLoading} from "../../utils/util";
import {setTimeout} from "timers";

export default {
  name: "AnswerPop",

  data () {
    return {
      requestCount: 0,
      img:{
        'background-image':'',
        'background-size': '100% 100%;',
        'max-height': '90vh;',
        'padding-top': '5.33333rem;',
        'padding-left': '1.6rem;',
        'padding-right': '1.33333rem;',
        'padding-bottom': '1.33333rem'
      },
      isWw:getStore('wwToken') != null,
      wwGlImg:null,
      wwRequestCount:0,
      successShowFlag: false,
      answerOptionFlag: false,
      answerDiv: true,
      productId: null,
      rightAnswers: false,//回答正确
      detailsShow: false,//答题时候的弹窗
      isError: false,//是否错误
      active: '', // 答题选中状态
      option: null, // 答题选中的选项
      show: false,
      // 是否显示答题弹框
      logoFlag: true,
      // 是否显示知识攻略弹框
      guidelineFlag: false,
      answerFlag: false,
      list: [
        {
          "params": {

          },
          "platformId": null,
          "id": "caa76b2fc94f48c99bdc8e5c48f7e793",
          "productId": "e3f13fb384e34cb194f895a8b2b73a48",
          "orderId": "2dbf5bb7dd054ff89177c79855ec8640",
          "userId": "07d0076c57f84d349d2bbb1761f4f70e",
          "userType": 1,
          "transferUserType": null,
          "number": "12121218#00001/05000",
          "hashCode": "0xAf340EF77b14b16fE06DBD0A2A5a4E5d30C24Be3",
          "realHashCode": "1000234327429845671528412106330794602192263334883",
          "payPassword": null,
          "transferId": null,
          "transferUserId": null,
          "transferHashCode": "0xb1e2d963972de777cd4b433b34ed35775967966e29cc2291dd173c7149a1f8b2",
          "transferTime": null,
          "isTransfer": 0,
          "status": true,
          "deleted": false,
          "createTime": "2023-05-15 02:09:15",
          "updateTime": "2023-05-15 02:10:42",
          "image": "https://images.wenwuchain.com/wenwu-images/common/2023-05-13/1885b4eb-c170-3052-a1f9-41821b0cc36f.png",
          "shareImage": null,
          "productName": "“文物中的美好生活”数字纪念徽章",
          "generatorTime": "2023-05-15 02:09:15",
          "generatorTimeStr": "2023年05月15日",
          "threedType": 2,
          "transeferCount": null,
          "seriesId": 32,
          "threedBadgeReleaseUrl": "common/2023-05-13/c5ea2b20-1bf7-3a0e-b3a7-4c46468d327b.png",
          "threedBadgeUnreleaseUrl": null,
          "threedBadgeBuyUrl": "common/2023-05-13/1885b4eb-c170-3052-a1f9-41821b0cc36f.png",
          "threedStatus": true,
          "productType": null,
          "productHashCode": null,
          "hashCodeOld": null,
          "hashCodeNew": "0xAf340EF77b14b16fE06DBD0A2A5a4E5d30C24Be3",
          "transferHashCodeOld": null,
          "blockNumber": null,
          "userHashCode": null,
          "transferUserHashCode": null,
          "isRead": true,
          "isFree": null,
          "inputPrice": null,
          "receiveStatus": null,
          "useCouponImages": null,
          "useCouponThreed": null,
          "isSave": null,
          "CHANNEL": null
        },
        {
          "params": {

          },
          "platformId": null,
          "id": "961d4ff22de04a6e81433684ac6083fe",
          "productId": "a085d9efe04244bd804f816fe8e38b0f",
          "orderId": "bce2dc1807604680b86aaf02f6377abe",
          "userId": "07d0076c57f84d349d2bbb1761f4f70e",
          "userType": 1,
          "transferUserType": null,
          "number": "b1008#00001/00110",
          "hashCode": "0xAf863c51E8Ee5f929B8EB561FAD3d361A8b02aB8",
          "realHashCode": "1002066939360421246571378848855487249297083214520",
          "payPassword": null,
          "transferId": null,
          "transferUserId": null,
          "transferHashCode": "0xc8b465becc48610bf252b60f61ecbb8d6605cf507753033c74c8c8f46c546826",
          "transferTime": null,
          "isTransfer": 0,
          "status": true,
          "deleted": false,
          "createTime": "2023-04-26 22:09:37",
          "updateTime": "2023-05-15 00:16:52",
          "image": "https://images.wenwuchain.com/wenwu-images/common/2023-04-26/dadb0584-3c8d-33c6-a6d0-53a609d977d7.png",
          "shareImage": null,
          "productName": "狸好！星城玩家",
          "generatorTime": "2023-04-26 22:09:37",
          "generatorTimeStr": "2023年04月26日",
          "threedType": null,
          "transeferCount": null,
          "seriesId": 35,
          "threedBadgeReleaseUrl": "common/2023-04-26/dadb0584-3c8d-33c6-a6d0-53a609d977d7.png",
          "threedBadgeUnreleaseUrl": "common/2023-04-26/c1dd54f3-1cc6-3818-8a8d-47094635ff00.png",
          "threedBadgeBuyUrl": "common/2023-04-26/dadb0584-3c8d-33c6-a6d0-53a609d977d7.png",
          "threedStatus": true,
          "productType": null,
          "productHashCode": null,
          "hashCodeOld": null,
          "hashCodeNew": "0xAf863c51E8Ee5f929B8EB561FAD3d361A8b02aB8",
          "transferHashCodeOld": null,
          "blockNumber": null,
          "userHashCode": null,
          "transferUserHashCode": null,
          "isRead": true,
          "isFree": null,
          "inputPrice": null,
          "receiveStatus": null,
          "useCouponImages": null,
          "useCouponThreed": null,
          "isSave": null,
          "CHANNEL": null
        }
      ],
      productDetail: [],
      question: [
        {
          'id': 0,
          "q": '国际生物多样性日是几月几号？',
          'img': require('../../assets/popover-home/answer/answer_4.png'),
          'answer': [
            {
              'option': 'A',
              'optionQ': '5月20日'
            },
            {
              'option': 'B',
              'optionQ': '5月21日'
            },
            {
              'option': 'C',
              'optionQ': '5月22日'
            },
            {
              'option': 'D',
              'optionQ': '5月23日'
            }
          ],
          'rightAnser': 'C',
          'analysis': '联合国大会于2000年12月20日，通过第55/201号决议，宣布每年5月22日为“生物多样性国际日”，以增加对生物多样性问题的理解和认识。今年“生物多样性国际日”的主题为：从协议到协力：复元生物多样性。'
        },
        {
          'id': 1,
          "q": '生物多样性是生物（动物、植物、微生物）与环境形成的生态复合体以及与此相关的各种生态过程的综合。包含的层次有：',
          'img': require('../../assets/popover-home/answer/answer_5.png'),
          'answer': [
            {
              'option': 'A',
              'optionQ': '生态系统多样性 '
            },
            {
              'option': 'B',
              'optionQ': '物种多样性'
            },
            {
              'option': 'C',
              'optionQ': '遗传多样性'
            },
            {
              'option': 'D',
              'optionQ': '以上全部'
            }
          ],
          'rightAnser': 'D',
          'analysis': '生物多样性包含遗传多样性、物种多样性、生态系统多样性三个层次。遗传多样性是一个物种的个体之间或一个群体内不同个体的遗传变异总和。物种多样性是生物多样性在物种水平上的表现形式，指动物、植物及微生物种类的丰富性。生态系统多样性是指生态系统的类型、结构、组成、功能和生态过程的多样性。'
        },
        {
          'id': 2,
          "q": '“国际生物多样性日”数字纪念徽章上出现的动植物元素共提取自多少件文物？',
          'img': require('../../assets/popover-home/answer/answer_6.png'),
          'answer': [
            {
              'option': 'A',
              'optionQ': '2件 '
            },
            {
              'option': 'B',
              'optionQ': '3件 '
            },
            {
              'option': 'C',
              'optionQ': '4件 '
            },
            {
              'option': 'D',
              'optionQ': '5件 '
            }
          ],
          'rightAnser': 'A',
          'analysis': '此款“国际生物多样性日”数字纪念徽章形象融合了4件来自湖南博物院的院藏文物上的动植物元素，包含：唐代长沙窑青釉红绿彩花鸟纹执壶、明代牧童骑牛，丰富了整个徽章画面。'
        },
        {
          'id': 3,
          "q": '长沙降水充沛、气候温和、四季分明、雨热同期，非常适合花卉植物生长，以下哪种花是长沙的市花？',
          'img': require('../../assets/popover-home/answer/answer_8.png'),
          'answer': [
            {
              'option': 'A',
              'optionQ': '荷花 '
            },
            {
              'option': 'B',
              'optionQ': '杜鹃'
            },
            {
              'option': 'C',
              'optionQ': '迎春花'
            },
            {
              'option': 'D',
              'optionQ': '梅花'
            }
          ],
          'rightAnser': 'B',
          'analysis': '1985年11月30日，长沙市八届人民代表大会常务委员会第十四次会议通过决议，确定杜鹃花是长沙市花。杜鹃花又称映山红，长沙气候温和，降水丰富，土壤种类多样，非常适合杜鹃花的生长。杜鹃花寓意着顽强的生命力、质朴，象征长沙人民能吃苦耐劳的精神。'
        },
        {
          'id': 4,
          "q": '下列动物中，有“水中大熊猫”之称的是（）',
          'img': require('../../assets/popover-home/answer/answer_2.png'),
          'answer': [
            {
              'option': 'A',
              'optionQ': '金丝猴'
            },
            {
              'option': 'B',
              'optionQ': '长江江豚'
            },
            {
              'option': 'C',
              'optionQ': '华南虎'
            },
            {
              'option': 'D',
              'optionQ': '花䱻'
            }
          ],
          'rightAnser': 'B',
          'analysis': '长江江豚（学名：Neophocaena asiaeorientalis）：是哺乳纲、鲸目、鼠海豚科的江豚。因全球仅剩1000余头，有“水中大熊猫”之称，2023年1月底， 湘江长沙望城段发现三头长江江豚的身影，令人激动不已。江豚的保护，需要长期不断的努力与更多公众的参与。'
        },
      ],
      currentQuestion: []


    }
  },
  created(){
    this.currentQuestion = this.question[Math.floor(Math.random() * this.question.length)];
    // 获取星城玩家答题背景图
    this.$http.get('/wenwu-commodity/dict/detail?dict_key=answer').then(res => {
      if (res.data.code == 200) {
        this.img['background-image'] = "url('"+res.data.data.dictValue+"')" ;
      }
    })
  },
  mounted() {
    this.loadWwGl();
  },
  methods: {

    loadWwGl() {
      //获取文悟背景图
      this.$http.get(process.env.VUE_APP_WW_BASE_URL + '/wenwu-pubapi-services/dict/detail?dict_key=wwMuseumDescImg').then(res => {
        if (res.data.code == 200) {
          this.wwGlImg =  res.data.data.dictValue;
        }
      })
    },
    closeAll () {
      this.guidelineFlag = false;
      this.answerFlag = false;
      this.rightAnswers = false
      this.logoFlag = true

    },
    // 点击去知识攻略弹框
    goToGuideline () {

      this.logoFlag = false
      this.guidelineFlag = true
    },
    // 立即答题
    gotoAnswer () {
      showLoading()

      this.answerFlag = true
      this.logoFlag = false
      clearToast()

      this.currentQuestion = this.question[Math.floor(Math.random() * this.question.length)];
    },
    // 箭头取消事件
    cancel () {
      this.show = true
    },
    closeAnser (a) {
      this.rightAnswers = false
    },
    // 暂不领取
    quit () {
      this.rightAnswers = false
      this.$emit('closeAnswer')
      this.$emit('receiveStatus')
    },
    // 路由回退
    goBack () {
      this.logoFlag = true
      this.guidelineFlag = false
    },

    confirm (index, option) {
      this.active = index
      this.option = option
    },

    wwGetSckillProductWithParams (params) {
      //  提交订单

      this.$http.get(process.env.VUE_APP_WW_BASE_URL + "/wenwu-order/order/result?product_id=" + params.productId).then(res2 => {
        //调用支付接口，免费商品不会支付，只执行相关业务逻辑

        if (res2.data.code == 6003) {

          if (this.wwRequestCount < 5) {
            setTimeout(() => {
              this.wwRequestCount++;
              this.wwGetSckillProductWithParams(params)
            }, 500)
          }

        } else {
          this.$http.post(process.env.VUE_APP_WW_BASE_URL + "/wenwu-order/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {
            if (res3.data.code == 200) {
              //  订单提交完成显示【答对了，去查看】的框
              clearToast()
              this.rightAnswers = true
              //  查看徽章列表
              this.$emit('getGoods')

            }else{
              showFailToast(res3.data.message, 1000);
              this.$emit('closeAnswer')

            }
          })
        }

      })

    },
    //  确认提交答案
    submit () {
      //   答对了
      if (this.currentQuestion.rightAnser == this.option) {
        showLoading()

        this.option = null
        this.answerFlag = false
        if (getStore('wwToken')) {
          this.$http.get(process.env.VUE_APP_WW_BASE_URL + '/wenwu-pubapi-services/dict/detail?dict_key=questionsBadge').then(res => {
            if (res.data.code == 200) {
              this.productId = res.data.data.dictValue
              let params = {
                'productId': this.productId,
                'activityType': '1'
              }
              this.$http.post(process.env.VUE_APP_WW_BASE_URL + '/wenwu-order/order/seckill', params).then(res1 => {
                if (res1.data.code == 200) {
                  this.wwGetSckillProductWithParams(params);
                } else {
                  showFailToast(res1.data.message, 1000);
                  this.$emit('closeAnswer')

                }
              })


            }
          })
        } else if (getStore('token')) {
          this.$http.get('/wenwu-commodity/dict/detail?dict_key=questionsBadge').then(res => {
            if (res.data.code == 200) {
              this.productId = res.data.data.dictValue
              let params = {
                'productId': this.productId,
                // 'activityType': '1'
              }
              //  提交订单
              this.$http.post('/wenwu-user/myCsOrder/myCseckill', params).then(res1 => {
                // 领取成功
                if (res1.data.code == 200) {
                  clearToast()
                  this.rightAnswers = true
                  this.$emit('receiveStatus')
                } else {
                  showFailToast(res1.data.message, 1000);
                  this.$emit('closeAnswer')

                }
              })
            }
          })
        } else {
          showFailToast('请先登陆', 1000);
          this.$emit('closeAnswer')
        }


        // this.$emit('rightAnswers')
        // alert('正确答案:' + this.currentQuestion.rightAnser + '已选答案:' + this.option + this.currentQuestion.analysis)
        // 错了
      } else {
        if (this.option != null) {
          this.isError = true
        }
        // this.option = null
      }
    },

    // pay(productId){
    //   // this.$http.get("/wenwu-user/order/result?product_id=" + productId).then(res2 => {
    //     // if (res2.data.code == 200) {
    //         //调用支付接口，免费商品不会支付，只执行相关业务逻辑
    //         this.$http.post("/wenwu-user/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {
    //           if (res3.data.code == 200) {
    //             //  订单提交完成显示【答对了，去查看】的框
    //             clearToast()
    //             this.rightAnswers = true
    //             this.$emit('receiveStatus')
    //
    //             //  查看徽章列表
    //             // this.$http.get("/wenwu-user/order/series").then(res4 => {
    //             //   if (res4.data.code = 200) {
    //             //     //  找到当前的这个徽章 存储徽章信息
    //             //     res4.data.data.productHashList.forEach(item => {
    //             //       if (item.productId == params.productId) {
    //             //         return this.productDetail = item
    //             //       }
    //             //     });
    //             //   }
    //             // })
    //           } else {
    //             showFailToast(res3.data.message, 1000);
    //             this.$emit('closeAnswer')
    //           }
    //         })
    //
    //   //   }else{
    //   //
    //   //   }
    //   //
    //   // })
    // },
    // pay(productId){
    //   this.$http.get("/wenwu-user/order/result?product_id=" + productId).then(res2 => {
    //     if (res2.data.code == 6003) {
    //       if (this.requestCount < 5) {
    //         setTimeout(() => {
    //           this.requestCount++;
    //           this.pay(this.productId)
    //         }, 500)
    //       }
    //     }else{
    //     //调用支付接口，免费商品不会支付，只执行相关业务逻辑
    //     this.$http.post("/wenwu-user/pay/order", { orderId: res2.data.data, payType: 0 }).then((res3) => {
    //       if (res3.data.code == 200) {
    //         //  订单提交完成显示【答对了，去查看】的框
    //         clearToast()
    //         this.rightAnswers = true
    //         this.$emit('receiveStatus')
    //
    //         //  查看徽章列表
    //         // this.$http.get("/wenwu-user/order/series").then(res4 => {
    //         //   if (res4.data.code = 200) {
    //         //     //  找到当前的这个徽章 存储徽章信息
    //         //     res4.data.data.productHashList.forEach(item => {
    //         //       if (item.productId == params.productId) {
    //         //         return this.productDetail = item
    //         //       }
    //         //     });
    //         //   }
    //         // })
    //       } else {
    //         showFailToast(res3.data.message, 1000);
    //         this.$emit('closeAnswer')
    //       }
    //     })
    //     }
    //
    //   })
    // },
    //  再来一次
    again () {
      this.isError = false
      this.active = null
      this.option = null
      this.currentQuestion = this.question[Math.floor(Math.random() * this.question.length)];
    },
    //  关闭选择
    closeQuesion () {
      this.$emit('changIsShow')
    },
    //  回退
    back(){

      this.logoFlag = true
      this.answerFlag = false

    },
    //  点击查看
    views () {
      // 关闭弹窗跳转徽章页面
      this.$emit('closeAnswer')

      this.rightAnswers = false;
      // this.$router.push({ path: '/nftDetail', query: { productId:this.productId } })
      // this.$router.push({ path: '/nftDetail', query: { productDetail: JSON.stringify(this.productDetail), isShow: true } })

      if(getStore('wwToken')){
        showSuccessToast('领取成功,请到个人中心-我的徽章中查看!');
      }else{
        this.$router.push({ path: '/digitalListPage' })
      }




    },

    // 下面三个函数：等图片加载完成之后再展示文字
    goodsListItemImg () {
      this.answerDiv = true
    },
    answerOption () {
      this.answerOptionFlag = true
    },
    successShow () {
      this.successShowFlag = true
    }



  },
}

</script>

<style scoped lang="scss" src="./index.scss"></style>
