<template>
  <div class="guideBox">
    <!-- 引导页 -->
    <!-- <div class=""> -->
    <img src="../../assets/popover-home/guide-page/guide_1.png" alt />
    <div class="guideButton" @click="gotoIndex">
      <img src="../../assets/popover-home/guide-page/guide_2.png" alt />
    </div>
    <!-- </div> -->
    <div class="guideShare">
      <div class="indexImg">
        <img @click="gotoIndex" src="../../assets/popover-home/guide-page/home_icon.png" alt />
      </div>

      <!-- <div> -->
      <!-- <img src="../../assets/popover-home/guide-page/矩形备份.png" alt=""> -->

      <div @click="share" class="ShareImg">
        <img src="../../assets/popover-home/guide-page/share_1.png"/>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>

import {shareInfo2} from "../../api/user";
import {getUserAgentUa, showFailToast, userAgentUa} from "../../utils/util";

export default {
  name: 'newHome',


  data () {
    return {
    }
  },
  methods: {
    wakeApp (){

      const config = {
        packageName: 'com.changsha.apps.android.mycs',
        schema: 'cssmy',
        web_link: 'https://mycs.csbtv.com/cssmyapp/'
      }
      let urlParam = {
        android_min_version: '54',
        appId: 'cssmy',
        channel: '3',
        city: '430100',
        code: '',
        contentUrl: '',
        imageUrl: 'https://cdn-oss.zhcs.csbtv.com/zhcs-prd/images/613c5b5d856fb900013ad050',
        isWebService: '3', // isWebService 1: 服务  2：H5  3：视频
        name: '',
        shareDesc: '',
        shareUrl: ''
      }
      urlParam = {...urlParam, ...param}
      console.log(urlParam)
      // 在微博或QQ中打开分享链接提示用户用浏览器打开
      if(userAgentUa.isInApp()){
        eshimin.bytedanceApplogEvent({
          eventName: 'home_click_button', // 例子
          eventParams: {} ,//例子
          onResponse:  (res)=> {
            console.log("bytedanceApplogEvent responseData:" + res);
          }
        })
        window.location.href = window.location.origin;

      }else  if (userAgentUa.android() && userAgentUa.weibo()) {
        console.log('请用浏览器打开')
        showFailToast(userAgentUa.android )
      } else if (userAgentUa.iphone() && (userAgentUa.weibo() || userAgentUa.mqq())) {
        console.log('请用浏览器打开')
        showFailToast('请用浏览器打开2')
      } else {
        let url,
          str,
          urlLink = "";
        for (let i in urlParam) {
          if (i == "imageUrl" || i == "shareUrl") {
            // 将参数'imageUrl'和 'shareUrl'编码
            urlParam[i] = encodeURIComponent(urlParam[i]);
          }
          // 拼接参数
          if (i !== "contentUrl") {
            urlLink += `${i}=${urlParam[i]}&`;
          }
        }
        urlLink = urlLink.substr(0, urlLink.length - 1);
        if (userAgentUa.android()) {
          str = `${config.schema}://welcome.applink/open?${urlLink}`;
          str =
            `https://a.app.qq.com/o/simple.jsp?pkgname=${config.packageName}&android_schema=` +
            encodeURIComponent(str);
          url = str;
        }
        if (userAgentUa.iphone()) {
          url = `${config.web_link}?${urlLink}`; //打开某手机上的某个app应用
        }
        console.log('🐞-[ url ]', url)
        window.location.href = url || config.web_link; //打开某手机上的某个app应用
      }
    },

    
     


    goBack () {
      this.$router.back(-1)
    },

    share () {
      //埋点上报
      eshimin.bytedanceApplogEvent({
        eventName: 'share_click_button', // 例子
        eventParams: { 'share_name': shareInfo2.title, 'title_name': '518博物馆分享' },//例子
        onResponse: (res) => {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      });

      eshimin.share({
        version: 0,
        title: shareInfo2.title,
        content: shareInfo2.desc,
        imgUrl: shareInfo2.logo,
        link: shareInfo2.link,
        onResponse: function (res) {
          console.log("share responseData:" + res);
        }
      })
    },


   
    gotoIndex () {
      this.$emit('closeGuide')
    }
  }
}

</script>

<style scoped>
.guideBox {
  position: relative;
  /* width: 100vh; */
  /* height: 100vh; */
}
.guideBox img {
  width: 100%;
  /* height: 100%; */
}
.guideButton {
  position: absolute;
  width: 90%;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, 0);
}
.guideButton img {
  width: 100%;
  height: 100%;
}
.guideShare {
  width: 90%;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
}
.indexImg img,
.ShareImg img {
  width: 30%;
}

.ShareImg {
  text-align: end;
}
</style>
