<template>
    <div :hidden="hidden">
        <div class="certification" >
            <span class="span1">完成实名认证</span>
            <span @click="toApprove" class="span2">实名认证</span>
        </div>
    </div>
</template>

<script >
import { onMounted,ref} from "vue";
import {setStore,getStore} from "../../utils/util";
import { useRouter } from "vue-router";
export default{
    name:"certification",
    setup(){
      const $router = useRouter();
      const hidden=ref(false);
      onMounted(()=>{
        hidden.value= getStore("isValid") == null || getStore("isValid") == false ? false: true;
      })
      const toApprove=(()=>{
        $router.push({
          name: "approve",
          params: {}
        })
      })
      return{
         hidden,
         toApprove
      }
    }
}

</script>

<style scoped lang="scss" src="./index.scss"></style>
