

export default [{
  path: '/orderDetail',
  name: 'orderDetail',
  meta: {
    index: 27
  },
  params:{},
  component: () => import('@/views/orderDetail/index'),
}]
